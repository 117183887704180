import React from 'react'
import EssaysPage from 'src/components/Page/Author/Essays'



const EssaysPageContainer = ({ location }) => {
    return <EssaysPage location={location} />
}

export default EssaysPageContainer

